import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiUrlsService} from '../api-urls/api-urls.service';
import {Observable} from 'rxjs';
import {UserModel} from '../../models/user';
import {map} from 'rxjs/operators';
import {Subject} from 'rxjs';
import { SessionService } from '../session';
import {environment} from '../../../environments/environments.dev';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { ToastService } from '../../services/toast/toast.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private eventSubject = new Subject<any>();
    private deviceConnectionSubject = new Subject<any>();
    addDeviceEvent$ = this.eventSubject.asObservable();
    deviceConnectionEvent$ = this.deviceConnectionSubject.asObservable();
    private userChangedSubject = new Subject<any>();
    // Observable string streams
    userAnnounced$ = this.userChangedSubject.asObservable();

    constructor (
        private http: HttpClient,
        private apiUrlsService: ApiUrlsService,
        private sessionService: SessionService,
        private emailComposer: EmailComposer,
        private toastService: ToastService,
    ) {}

    /**
     * @ngdoc method
     *
     * @description
     * Method that gets an user from the server
     *
     */
    
    getUser (id: string): Observable<UserModel> {
        return this.http.get(
            this.apiUrlsService.USER.get(id)
        ).pipe(
            map((response: any) => UserModel.deserialize(response))
        );
    }

    setTemperatureScale (user, scale) {
        return this.http.put(
            this.apiUrlsService.USER.setTemperatureScale(user.id), {temperatureScale: scale}
        ).pipe(
            map((response: any) => UserModel.deserialize(response))
        );
    }

    setFcmTokens (user, tokens) {
        return this.http.put(
            this.apiUrlsService.USER.setFcmTokens(user.id), {fcmTokens: tokens}
        ).pipe(
            map((response: any) => UserModel.deserialize(response))
        );
    }

    publishEvent(data: any) {
        this.eventSubject.next(data);
    }

    publishUserUpdate(user: UserModel) {
        this.userChangedSubject.next(user);
    }
  
    getEvent(): Subject<any> {
        return this.eventSubject;
    }

    /**
     * Publish a true is a device is being conencted and a false
     * if device connection has finished
     */
    publishDeviceConnectionStatus(deviceBeingConnected: boolean) {
        this.deviceConnectionSubject.next(deviceBeingConnected);
    }


    support() {
        const user = this.sessionService.getCurrentUser();
        const username = user ? user.username : null;
        const body = username ? `<p>Please do not remove this line</p><p>${username}</p>` : '';

        const email = {
            to: environment.SUPPORT_EMAIL,
            subject: 'NIWA Support',
            body,
            isHtml: true
        };

        // Send a text message using default options
        this.emailComposer.open(email)
            .catch((err) => {
                this.toastService.presentErrorToast('It was not possible to send email', 'top', 3000);
            });
    }

    async parseTempToScale(temp: number) {
        const currentUser = await this.sessionService.getCurrentUserSync();
        const temperatureScale = currentUser.temperatureScale ? currentUser.temperatureScale : 'celsius';

        return temperatureScale === 'celsius' ? Math.round(temp) : Math.round(temp * 1.8);
    }
}
