import { Injectable } from '@angular/core';

import {ParticleUnitModel} from '../../models/particle-unit/particle-unit.model';
import {StageInstanceModel} from '../../models/grow-program-instance/stages-instance.model';
import {SessionService} from '../session';

import {Subject} from 'rxjs';
import _ from 'lodash';
import {UserModel} from '../../models/user';
import {IoTDeviceModel} from '../../models/iot-device/iot-device.model';
import {GrowRecipeInstanceModel} from '../../models/grow-recipe-instance/grow-recipe-instance.model';

@Injectable({
    providedIn: 'root'
})
export class CurrentDeviceStatusService {
    private currentParticleUnit: ParticleUnitModel;
    private currentIoTDevice: IoTDeviceModel;
    private currentGrowRecipeInstance: GrowRecipeInstanceModel;
    private currentStateInstance: StageInstanceModel;

    private currentParticleUnitSubject: Subject<ParticleUnitModel> = new Subject<ParticleUnitModel>();
    private currentIoTDeviceSubject: Subject<IoTDeviceModel> = new Subject<IoTDeviceModel>();
    private currentGrowRecipeInstanceSubject: Subject<GrowRecipeInstanceModel> = new Subject<GrowRecipeInstanceModel>();
    private currentStateInstanceSubject: Subject<StageInstanceModel> = new Subject<StageInstanceModel>();

    constructor (
        private sessionService: SessionService,
    ) {}

    init(deviceIndex: number) {
        const session = this.sessionService.getCurrentUser();
        this.sessionService.getUserObservable()
            .subscribe((user: UserModel) => {
                this.currentParticleUnit = user && user.particles && user.particles.length ? user.particles[deviceIndex] : null;
                this.currentIoTDevice = user && user.devices && user.devices.length ? user.devices[deviceIndex] : null;
                // this.currentGrowProgramInstance = this.currentParticleUnit && this.currentParticleUnit.growProgramInstance ?
                //     this.currentParticleUnit.growProgramInstance : null;
                this.currentGrowRecipeInstance = this.currentIoTDevice && this.currentIoTDevice.growRecipeInstance ?
                    this.currentIoTDevice.growRecipeInstance : null;
                this.currentStateInstance = this.currentGrowRecipeInstance
                    ? _.find(this.currentGrowRecipeInstance.stages, { active: true })
                    : null
                ;

                this.currentParticleUnitSubject.next(this.currentParticleUnit);
                this.currentIoTDeviceSubject.next(this.currentIoTDevice);
                this.currentGrowRecipeInstanceSubject.next(this.currentGrowRecipeInstance);
                this.currentStateInstanceSubject.next(this.currentStateInstance);
            })
        ;
    }

    getGrowProgram() {
        return this.currentGrowRecipeInstance;
    }

    getGrowProgramSubject() {
        return this.currentGrowRecipeInstanceSubject;
    }

    getParticleUnit() {
        return this.currentParticleUnit;
    }

    getIoTDevice() {
        return this.currentIoTDevice;
    }

    async getIoTDeviceSync() {

        let currentDevice = this.currentIoTDevice;

        if(!this.currentIoTDevice) {
            const user = await this.sessionService.getUserObservable().toPromise();
            currentDevice = user && user.devices && user.devices.length ? user.devices[0] : null;
        }

        return currentDevice;
    }

    setIoTDevice(iotDevice: IoTDeviceModel) {
        this.currentIoTDevice = iotDevice;
        // this.currentGrowProgramInstance = this.currentParticleUnit && this.currentParticleUnit.growProgramInstance ?
        //     this.currentParticleUnit.growProgramInstance : null;
        this.currentGrowRecipeInstance = this.currentIoTDevice && this.currentIoTDevice.growRecipeInstance ?
            this.currentIoTDevice.growRecipeInstance : null;
        this.currentStateInstance = this.currentGrowRecipeInstance
            ? _.find(this.currentGrowRecipeInstance.stages, { active: true })
            : null
        ;
        this.currentIoTDeviceSubject.next(this.currentIoTDevice);
        this.currentGrowRecipeInstanceSubject.next(this.currentGrowRecipeInstance);
        this.currentStateInstanceSubject.next(this.currentStateInstance);
    }

    getParticleUnitSubject() {
        return this.currentParticleUnitSubject;
    }

    getStage() {
        return this.currentStateInstance;
    }

    getStageSubject() {
        return this.currentStateInstanceSubject;
    }

    setStage(stage: StageInstanceModel) {
        this.currentStateInstance = stage;
        this.currentStateInstanceSubject.next(this.currentStateInstance);
    }

    setCurrentDevice() {
        this.sessionService.getUserObservable()
            .subscribe((user: UserModel) => {
            });
    }

    updateCurrentIoTDevice(ioTDevice: IoTDeviceModel) {
        this.currentIoTDevice = ioTDevice;
        this.currentIoTDeviceSubject.next(this.currentIoTDevice);
    }

    setCurrentGrowRecipeInstance(value) {
        this.currentGrowRecipeInstance = value;
        this.currentGrowRecipeInstanceSubject.next(value);
    }
}

