import { StageModel } from '../grow-recipe';

export class StageInstanceModel extends StageModel {
    public done: boolean;
    public active: boolean;

    static deserialize(jsonObject: any): StageInstanceModel {
        const instance = new StageInstanceModel();

        if (jsonObject === undefined || jsonObject === null) {
            return undefined;
        }

        const stage: StageModel = super.deserialize(jsonObject);

        Object.keys(jsonObject).forEach(prop => {
            if (StageModel.isProperty(prop)) {
                instance[prop] = stage[prop];
            } else {
                instance[prop] = jsonObject[prop];
            }
        });

        return instance;
    }
}
