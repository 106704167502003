import { Injectable } from '@angular/core';
import {CurrentDeviceStatusService} from '../current-device-status/current-device-status.service';
import {SessionService} from '../session';

@Injectable({
  providedIn: 'root'
})
export class StartupService {

  constructor(
      private currentDeviceStatusService: CurrentDeviceStatusService,
      private sessionService: SessionService
  ) {}

  run() {
    // Set up selected niwa if there was one previously selected
    this.sessionService.getCurrentDeviceIndex().then(index => {
      if (index) {
        this.currentDeviceStatusService.init(index);
      } else {
        this.currentDeviceStatusService.init(0);
      }
    });

  }
}
