import { UserModel } from '../user';

/**
 * Data model for auth server response
 */
export class SessionModel {
    public accessToken: any;
    public refreshToken: string;
    public tokenId: string;
    public user: UserModel;

    constructor (accessToken?: any, refreshToken?: any, tokenId?: string, user?: UserModel) {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
        this.tokenId = tokenId;
        this.user = user;
    }
    static deserialize(jsonObject: any): SessionModel {
        const instance = new SessionModel();

        if (jsonObject === undefined || jsonObject === null) {
            return undefined;
        }

        const userHydrator = (jsonPayload) => UserModel.deserialize(jsonPayload);

        const mapProperties = {
            user: userHydrator,
        };

        Object.keys(jsonObject).forEach(prop =>
            instance[prop] = mapProperties[prop] !== undefined
                ? mapProperties[prop](jsonObject[prop])
                : jsonObject[prop]
            )
        ;

        return instance;
    }
}
