/**
 * Data model for creator model
 */
export class CreatorModel {
    public id: string;
    public username: string;

    static deserialize(jsonObject: any): CreatorModel {
        const instance = new CreatorModel();

        if (jsonObject === undefined || jsonObject === null) {
            return undefined;
        }

        /*Object.keys(jsonObject).forEach(prop => {
            if (prop === '_particles') {
                instance['particles'] = mapProperties['particles'](jsonObject['_particles'])
            } else {
                instance[prop] = mapProperties[prop] !== undefined
                    ? mapProperties[prop](jsonObject[prop])
                    : jsonObject[prop]
            }
        });*/
        Object.keys(jsonObject).forEach(prop => {
            instance[prop] = jsonObject[prop];
        });

        return instance;
    }
}
