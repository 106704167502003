import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiUrlsService} from '../api-urls/api-urls.service';
import {SessionService} from '../session';
import {CurrentDeviceStatusService} from '../current-device-status/current-device-status.service';
import {ToastService} from '../toast/toast.service';

import * as moment from 'moment';
import {IoTDeviceModel} from '../../models/iot-device/iot-device.model';
import {SessionModel} from '../../models/session/session.model';

@Injectable({
    providedIn: 'root'
})

export class IoTDeviceService {

    constructor(
        private http: HttpClient,
        private apiUrlsService: ApiUrlsService,
        private sessionService: SessionService,
        private currentDeviceStatusService: CurrentDeviceStatusService,
        private toastService: ToastService
    ) { }

    associateDevice(bssId: string) {
        const user = this.sessionService.getCurrentUser();

        // const timezone = moment().utcOffset() / 60;
        const timezone = Intl.DateTimeFormat('it-IT',{timeZone:'Europe/Rome'}).resolvedOptions().timeZone;
        return this.http.post(this.apiUrlsService.USER.associateIoTDevice(), {
            owner: user.id,
            awsId: 'niwaesp32_' + bssId.toUpperCase(),
            type: 'niwa_grow_hub',
            bssId: bssId.toUpperCase(),
            timezone,
            name: ''
        });
    }

    publishMqttMessage(topic, payload, tokenId) {
        return this.http.post(this.apiUrlsService.IOT_DEVICE.publishMqttMessage(), JSON.stringify({
            topic,
            payload,
            tokenId
        }));
    }

    publishCertMqttMessage(topic, payload) {
        return this.http.post(this.apiUrlsService.IOT_DEVICE.publishCertMqttMessage(), JSON.stringify({
            topic,
            payload
        }));
    }

    publishCertLiveDashMqttMessage(topic, payload) {
        return this.http.post(this.apiUrlsService.IOT_DEVICE.publishCertMqttLiveDashMessage(), JSON.stringify({
            topic,
            payload
        }));
    }

    update(deviceId, body) {
        return this.http.put(this.apiUrlsService.IOT_DEVICE.update(deviceId), body);
    }

    updateAlarmsSettings(deviceId, body) {
        return this.http.put(this.apiUrlsService.IOT_DEVICE.updateAlarmsSettings(deviceId), body);
    }

    updateOffsets(deviceId, body) {
        return this.http.put(this.apiUrlsService.IOT_DEVICE.offsets(deviceId), body);
    }

    updateLeafTempOffsets(deviceId, body) {
        return this.http.put(this.apiUrlsService.IOT_DEVICE.leafTempOffsets(deviceId), body);
    }

    updateOutlets(deviceId, body) {
        return this.http.put(this.apiUrlsService.IOT_DEVICE.outlets(deviceId), body);
    }

    apConnection (ssid, passwd) {
        return this.http.get(this.apiUrlsService.IOT_DEVICE.apModeConnect(ssid, passwd));
    }

    sendLiveDashboardMessage(tokenId: string, device: IoTDeviceModel, payload: string) {
        // tslint:disable-next-line:max-line-length
        const topic = `$aws/things/niwa_test_group/niwaesp32_${device.bssId.toUpperCase() + '/' + device.bssId.toUpperCase()}/device/control/config`;
        return this.http.post(this.apiUrlsService.IOT_DEVICE.publishMqttMessage(), JSON.stringify({
            topic,
            payload,
            tokenId
        }));
    }

    testConenction(ssid, passwd) {
        return this.http.get(`http://192.168.4.1?ssid=${ssid}&password=${passwd}`);
    }

    sendEmptyConfig(topic, timezone) {
        return this.http.post(this.apiUrlsService.IOT_DEVICE.sendEmptyConfig(), JSON.stringify({
            topic,
            timezone
        }));
    }
}
