import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environments.dev';

@Injectable({
    providedIn: 'root'
})
export class ApiUrlsService {
    static API_URL = `${environment.BASE_API_URL}`;

    public AUTH = {
        login: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/auth`
        ),
        loginWithProvider: (provider): string => (
            `${ApiUrlsService.API_URL}/api/v1/auth/${provider}/callback`
        ),
        register: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/auth/register`
        ),
        logout: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/auth/logout`
        ),
        verify: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/auth/confirmation`
        ),
        resend: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/auth/confirmation/resend-code`
        ),
        forgotPassword: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/auth/forgot-password`
        ),
        changePassword: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/auth/change-password`
        ),
        changePasswordLogged: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/auth/change-password-logged`
        ),
        refresh: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/auth/refresh`
        )
    };

    public USER: any = {
        get: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/users/${id}`
        ),
        setTemperatureScale: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/users/${id}`
        ),
        setFcmTokens: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/users/${id}`
        ),
        /*setTemperatureScale: (scale: string): string => (
            `${ ApiUrlsService.API_URL }/api/v1/users/setTemperatureScale/${scale}`
        ),*/
        claimCode: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/users/getClaimCode`
        ),
        claimDevice: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/users/claimDevice`
        ),
        associateDevice: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/particle_units`
        ),
        associateIoTDevice: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/iot-devices`
        ),
        devices: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/particle_units/all`
        ),
        updateDevice: (deviceId: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/particle_units/${deviceId}`
        )
    };

    public IOT_DEVICE = {
        update: (deviceId): string => (
            `${ApiUrlsService.API_URL}/api/v1/iot-devices/${deviceId}`
        ),
        updateAlarmsSettings: (deviceId): string => (
            `${ApiUrlsService.API_URL}/api/v1/iot-devices/alarms-settings/${deviceId}`
        ),
        offsets: (deviceId): string => (
            `${ApiUrlsService.API_URL}/api/v1/iot-devices/offsets/${deviceId}`
        ),
        leafTempOffsets: (deviceId): string => (
            `${ApiUrlsService.API_URL}/api/v1/iot-devices/leaf-temp-offsets/${deviceId}`
        ),
        outlets: (deviceId): string => (
            `${ApiUrlsService.API_URL}/api/v1/iot-devices/outlets/${deviceId}`
        ),
        publishMqttMessage: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/iot-devices/mqtt`
        ),
        publishCertMqttMessage: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/iot-devices/cert-mqtt`
        ),
        publishCertMqttLiveDashMessage: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/iot-devices/cert-mqtt/live-dash`
        ),
        apModeConnect: (ssid, passwd): string => (
            `http://192.168.4.1?ssid=${ssid}&password=${passwd}`
        ),
        sendEmptyConfig: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/iot-devices/empty-config`
        )
    };

    public GROW_PROGRAM: any = {
        /* getAll: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow_programs`
        ),
        create: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow_programs`
        ),
        get: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow_programs/${id}`
        ),
        update: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow_programs/${id}`
        ),
        activate: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow_instances`
        ),
        remove: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow_instances/${id}`
        ),
        delete: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow_programs/${id}`
        ),
        advanceStage: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow_instances/${id}/next-stage`
        ),
        goBackStage: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow_instances/${id}/previous-stage`
        ),
        updateInstance: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow_instances/${id}`
        ) */
    };

    public GROW_RECIPE: any = {
        getAll: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow-recipes`
        ),
        create: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow-recipes`
        ),
        get: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow-recipes/${id}`
        ),
        update: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow-recipes/${id}`
        ),
        activate: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow-recipes-instances`
        ),
        activateWithStageIndex: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow-recipes-instances/stage-index`
        ),
        remove: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow-recipes-instances/${id}`
        ),
        removeNotUpdateDevice: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow-recipes-instances/not-update-iot-device/${id}`
        ),
        delete: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow-recipes/${id}`
        ),
        advanceStage: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow-recipes-instances/${id}/next-stage`
        ),
        goBackStage: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow-recipes-instances/${id}/previous-stage`
        ),
        goToStage: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow-recipes-instances/${id}/set-stage`
        ),
        updateInstance: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/grow-recipes-instances/${id}`
        )
    };

    public PARTILE_UNIT: any = {
        getData: (particleId: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/particle_units/data?particleId=${particleId}`
        ),
        getDeviceData: (deviceId: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/particle_units/data?deviceId=${deviceId}`
        ),
        getDeviceIds: (): string => (
            `${ApiUrlsService.API_URL}/api/v1/particle_units/deviceIds`
        ),
        updateAlarmsSettings: (id: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/particle_units/alarms-settings/${id}`
        )
    };

    public PARTICLE_UNIT_DATA_LOGS: any = {
        getAll: (deviceId: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/data-logs?deviceId=${deviceId}`
        ),
        getDataLogsForDates: (deviceId, fromDate, toDate, type): string => (
            // tslint:disable-next-line:max-line-length
            `${ApiUrlsService.API_URL}/api/v1/data-logs/range?deviceId=${deviceId}&type=${type}&fromDate=${fromDate}&toDate=${toDate}`
        ),
        getDataLogsForDatesV2: (deviceId: string, fromDate: string, toDate: string): string => (
            // tslint:disable-next-line:max-line-length
            `${ApiUrlsService.API_URL}/api/v1/data-logs/rangev2?deviceId=${deviceId}&fromDate=${fromDate}&toDate=${toDate}`
        ),
        getLastDataLogsFromDevice: (deviceId: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/data-logs/device/${deviceId}`
        )
    };

    public ALARMS: any = {
        getByUserId: (userId: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/alarms/user/${userId}`
        ),
        updateByUserId: (userId: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/alarms/user/${userId}`        
        ),
        updateAlarm: (alarmId: string): string => (
            `${ApiUrlsService.API_URL}/api/v1/alarms/${alarmId}`
        ),
        checkAlarmsByUserId: (userId: string) : string => (
            `${ApiUrlsService.API_URL}/api/v1/alarms/user/check/${userId}`
        )
    };
}
