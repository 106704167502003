import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'nw-button',
  templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
    @Input() label: string;
    @Input() icon: string;
    @Input() modifiers: string;
    @Input() isDisabled: boolean;
    @Input() buttonType: string;
    @Output() buttonClicked: EventEmitter<any>  = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit() {
    }

    onClick (value?: any) {
        this.buttonClicked.emit(value);
    }
}
