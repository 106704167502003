import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse, HttpClient} from '@angular/common/http';
import {BehaviorSubject, from, Observable, Observer, throwError} from 'rxjs';
import {Storage} from '@ionic/storage';
import {catchError, filter, map, switchMap, take, tap} from 'rxjs/operators';
import {SessionModel} from '../../models/session/session.model';
import {ApiUrlsService} from '../api-urls/api-urls.service';
import {SessionService} from '../session';
import {Router} from '@angular/router';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

    private refreshInFlight: Observable<SessionModel> = undefined;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );
    private refreshTokenInProgress = false;

    constructor(
        private storage: Storage,
        private apiUrlsService: ApiUrlsService,
        private http: HttpClient,
        private sessionService: SessionService,
        public router: Router,
        private spinnerService: SpinnerService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // YOU CAN ALSO DO THIS
        // const token = this.authenticationService.getToke()

        // @ts-ignore
        return from(this.storage.get('accessToken'))
            .pipe(
                switchMap(token => {
                    if (token) {
                        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
                    }

                    if (!request.headers.has('Content-Type')) {
                        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
                    }

                    /* if (this.debug) {
                      request = request.clone({ url: this.url + request.url + '?XDEBUG_SESSION_START=1'});
                    } */

                    return next.handle(request).pipe(
                        map((event: HttpEvent<any>) => {
                            if (event instanceof HttpResponse) {
                                // do nothing for now
                            }
                            return event;
                        }),
                        catchError((error: HttpErrorResponse) => {
                            const requestSession = this.sessionService.getCurrentSession();
                            // const parsedError = this.parseError(error);

                            const status =  error.status;
                            const reason = error && error.error.reason ? error.error.reason : '';

                            if(requestSession && error.status == 401) {
                                this.http
                                    .post(this.apiUrlsService.AUTH.refresh(), {
                                        refreshToken: requestSession.refreshToken
                                    })
                                    .pipe(
                                        catchError(err => {
                                            this.logOut();
                                            return throwError(error);
                                        })
                                    ).subscribe((refreshedAccessToken: any) => {
                                        // this.logger.info('Successfully refreshed the access token, setting new one');
                                        requestSession.accessToken = refreshedAccessToken.accessToken;
                                        requestSession.tokenId = refreshedAccessToken.tokenId;
                                        this.sessionService.setSession(requestSession);
                                        this.sessionService.tokenRefreshed(requestSession);

                                        request = request.clone({
                                            setHeaders: {
                                                Authorization: 'Bearer ' + refreshedAccessToken.accessToken
                                            }
                                        });
                                        return next.handle(request);
                                    }
                                );
                            }else {
                                return throwError(error);
                            }
                        })
                    );
                })
            );

    }

    private refreshToken(requestSession: SessionModel, observer: Observer<string>): void {
        this.http
            .post(this.apiUrlsService.AUTH.refresh(), {
                refreshToken: requestSession.refreshToken
            })
            .pipe(
                map((res: any) => res && (res._body || res.body) ? res.json() : {}),
                map(refreshTokenResponse => refreshTokenResponse.accessToken),
                tap(refreshedAccessToken => {
                    // this.logger.info('Successfully refreshed the access token, setting new one');
                    requestSession.accessToken = refreshedAccessToken;
                    this.sessionService.setSession(requestSession);
                })
            )
            .subscribe(
                // Destroy the observable since we no longer need it
                refreshedToken => {
                    this.refreshInFlight = undefined;

                    // notify our observers that the refresh token has succeeded
                    observer.next(refreshedToken);
                    observer.complete();
                },
                error => {
                    // this.logger.warn('Failed to refresh the access token, clearing the session');
                    // If we can't refresh the token then we clear the session
                    const parsedError = this.parseError(error);

                    if (parsedError && parsedError.errorCode === 401) {
                        this.sessionService.clearSession();
                    }

                    this.refreshInFlight = undefined;

                    // notify our observers that the refresh token failed
                    observer.error(error);
                }
            );
    }

    private parseError<T>(error) {
        const errorCode  = error.status || 500;
        const errorMessage  = error ? error.message : 'There was a problem completing your request';

        return {
            previous: error,
            errorCode,
            errorMessage
        };
    }

    private logOut() {
        this.sessionService.clearSession();
        this.spinnerService.hide();
        this.router.navigate(['/sign-in']);
    }
}
