import {Component, OnInit, Input, HostListener} from '@angular/core';
import {IconService} from '../../services/icon/icon.service';

@Component({
  selector: 'nw-icon',
  templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
    @Input() icon: string;
    @Input() iconColor: string;
    @Input() iconWidth: string;
    @Input() iconHeight: string;
    screenHeight: number;
    screenWidth: number;
    iconService: IconService;
    path: string;
    iconStyles: object;

    constructor(iconService: IconService) {
        this.iconService = iconService;
        this.getScreenSize();
    }

    ngOnInit() {
        this.path = this.iconService.load(this.icon);
        this.iconStyles = {
            color: this.iconColor
        };
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
    }
}
