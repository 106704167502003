import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {

    private spinnerBehaviorSubject: BehaviorSubject<boolean>;
    private spinnerBlockingSubject: BehaviorSubject<boolean>;

    constructor() {
        this.spinnerBehaviorSubject = new BehaviorSubject<boolean>(false);
        this.spinnerBlockingSubject = new BehaviorSubject<boolean>(true);
    }

    getSpinnerBlockingState() {
        return this.spinnerBlockingSubject;
    }

    getSpinnerState() {
        return this.spinnerBehaviorSubject;
    }

    show(blocking = true, shouldHide = true) {
        this.spinnerBehaviorSubject.next(true);
        this.spinnerBlockingSubject.next(blocking);

        if(shouldHide) {
            setTimeout(() => {
                this.spinnerBehaviorSubject.next(false);
                this.spinnerBlockingSubject.next(false);
            }, 6000);
        }
    }

    hide() {
        this.spinnerBehaviorSubject.next(false);
    }

}
