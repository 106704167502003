import {Injectable} from '@angular/core';
import {SessionModel} from '../../models/session/session.model';
import {forkJoin, merge, Observable, Subject, zip} from 'rxjs';
import {LoginEvent, LoginMethod, LogoutEvent} from './session.types';
import {UserModel} from '../../models/user';
import { first, map, tap } from 'rxjs/operators';
import { Storage } from '@ionic/storage';

/**
 * @ngdoc
 *
 * @description The keys for the session data
 */
export const SESSION_STORAGE_KEY = 'accessToken';
export const SESSION_STORAGE_REFRESH_KEY = 'refreshToken';
export const SESSION_STORAGE_TOKEN_ID = 'tokenId';
export const SESSION_USER_KEY = 'user';
export const SESSION_USER_LEARNED_KEY = 'learned';
export const SESSION_SELECTED_NIWA = 'selectedNiwaIndex';

@Injectable({
    providedIn: 'root'
})

export class SessionService {

    private session: SessionModel;
    private loginSubject: Subject<LoginEvent> = new Subject<LoginEvent>();
    private logoutSubject: Subject<LogoutEvent> = new Subject<LogoutEvent>();
    private sessionSubject: Subject<SessionModel> = new Subject<SessionModel>();
    private userSubject: Subject<UserModel> = new Subject<UserModel>();

    private tokenChangedSubject = new Subject<any>();
    // Observable string streams
    tokenAnnounced$ = this.tokenChangedSubject.asObservable();


    constructor(
        private storage: Storage
    ) {

    }

    /**
     * @ngdoc
     * @description
     * Method to initialize the service by looking for the session and refresh token
     * using the `CookieWrapperService`. This will also set the `Authorization` header as default.
     */
    init() {
        this.loadSession()
            .pipe(
                first()
            )
            .subscribe((session: any) => {
                if (session && session.accessToken) {
                    this.setSession(session, 'passive');
                }
            })
        ;


        merge(
            this.loginSubject.asObservable().pipe(map(login => login.session.user)),
            this.logoutSubject.asObservable().pipe(map((_ => null)))
        ).subscribe(user => this.userSubject.next(user));

        // this.logger.info('Initialized');
    }

    /**
     * @ngdoc
     * @description
     * loads the current stored session
     */
    loadSession() {
        return zip(
            this.storage.get(SESSION_STORAGE_REFRESH_KEY),
            this.storage.get(SESSION_STORAGE_KEY),
            this.storage.get(SESSION_STORAGE_TOKEN_ID),
            this.storage.get(SESSION_USER_KEY),
            (refreshToken, accessToken, tokenId, user) => {
                // @ts-ignore
                return new SessionModel(accessToken, refreshToken, tokenId, UserModel.deserialize(JSON.parse(user)));
            }
        ).pipe(
            tap((session) => this.sessionSubject.next(session)),
            // catchError(() => this.storage.clear())
        );
    }

    /**
     * @ngdoc
     * @description
     * Sets the current session and triggers any login events associated with it
     */
    setSession(session: SessionModel, method?: LoginMethod) {
        if (!method) {
            method = 'passive';
        }

        // if there is no new session this should be a logout, not a login
        if (!session) {
            this.clearSession();
        }

        // this.logger.debug(`setSession(userId: ${session.user.id}, token: ${session.accessToken}, method: ${method})`);

        this.session = session;
        this._setTokens(session);
        this.loginSubject.next({method, session: this.session, user: this.session.user});
        this.sessionSubject.next(this.session);
    }

    setSessionAsync(session: SessionModel): Observable<any> {
        this.session = session;
        this.loginSubject.next({ method: 'passive', session: this.session, user: this.session.user });
        this.sessionSubject.next(this.session);
        return this._setTokens(session);
    }

    /**
     * @ngdoc
     * @description
     * Clears the current session and triggers any logout events associated with it
     */
    clearSession(silent: boolean = false) {
        if (!this.session) {
            return;
        }

        const prevSession = this.session;

        // this.logger.debug(`clearSession(userId: ${prevSession.user.id}, token: ${prevSession.accessToken})`);

        this.session = null;

        this._unsetTokens();
        if (!silent) {
            this.sessionSubject.next(this.session);
            this.logoutSubject.next({session: prevSession, user: prevSession.user});
        }
    }

    /**
     * @ngdoc
     * @description
     * Gets the current user object
     *
     * @returns the current user
     */
    getCurrentUser() {
        return this.session ? this.session.user : null;
    }

    async getCurrentUserSync() {

        let user = this.session ? this.session.user : null;

        if(!user) {
            user = (await this.loadSession().toPromise()).user;
        }

        return user;
    }

    /**
     * @ngdoc
     * @description
     * Sets the current user object
     *
     * @param user the user to assign to the current session
     */
    setCurrentUser(user: UserModel) {
        if (this.session) {
            this.session.user = user;
            this.storage.set(SESSION_USER_KEY, JSON.stringify(user));
            this.userSubject.next(user);
        }
    }

    /**
     * @ngdoc
     * @description
     * Gets the current session object
     *
     * @returns the current session
     */
    getCurrentSession() {
        return this.session;
    }

    /**
     * @ngdoc
     * @description
     * Method to check if there is a user logged in.
     *
     * @returns returns true if a auth token is found, false otherwise.
     */
    isLoggedIn(): boolean {
        return !!(this.session && this.session.accessToken);
    }

    /**
     * @ngdoc
     * @description
     * Returns an obversable triggered when a login happens
     *
     * @returns the login observable
     */
    getLoginObservable(): Observable<LoginEvent> {
        return this.loginSubject;
    }

    /**
     * @ngdoc
     * @description
     * Returns an obversable triggered when a session change happens
     *
     * @returns the session observable
     */
    getCurrentSessionObservable(): Observable<SessionModel> {
        return this.sessionSubject;
    }

    /**
     * @ngdoc
     * @description
     * Returns an obversable triggered when a logout happens
     *
     * @returns the logout observable
     */
    getLogoutObservable(): Observable<LogoutEvent> {
        return this.logoutSubject;
    }

    /**
     * @ngdoc
     * @description
     * Returns an obversable triggered when there is a user
     *
     * @returns the user observable
     */
    getUserObservable(): Observable<UserModel> {
        return this.userSubject;
    }

    /**
     * @ngdoc
     * @description
     * Method to logout the current user. This will clear all information stored on localstorage.
     */
    logout() {
        this.clearSession();
    }

    /**
     * @ngdoc
     * @description
     * Method to mark user as he learned how it works.
     */
    setLearned() {
        const user = this.getCurrentUser();
        if (user) {
            return this.storage.set(SESSION_USER_LEARNED_KEY + user.username, true);
        }

        return Promise.resolve();
    }

    /**
     * @ngdoc
     * @description
     * Method to check if user has learned how it works.
     */
    getLearned() {
        const user = this.getCurrentUser();
        if (user) {
            return this.storage.get(SESSION_USER_LEARNED_KEY + user.username);
        }

        return Promise.resolve();
    }

    /**
     * Returns the index of the current niwa selected by the user
     * in his dashboard. The index belongs to user particles array
     */
    getCurrentDeviceIndex() {
        return this.storage.get(SESSION_SELECTED_NIWA);
    }

    /**
     * Sets the index of the current niwa selected by the user
     * in his dashboard. The index belongs to user particles array
     */
    setCurrentDeviceIndex(index: number) {
        this.storage.set(SESSION_SELECTED_NIWA, index);
    }

    /**
     * @ngdoc
     * @description
     * Method to save the tokens stored.
     */
    private _setTokens(session: SessionModel) {
        const promiseArray = [];

        if (session.accessToken) {
            promiseArray.push(this.storage.set(SESSION_STORAGE_KEY, session.accessToken));
        }

        if (session.refreshToken) {
            promiseArray.push(this.storage.set(SESSION_STORAGE_REFRESH_KEY, session.refreshToken));
        }

        if (session.tokenId) {
            promiseArray.push(this.storage.set(SESSION_STORAGE_TOKEN_ID, session.tokenId));
        }

        if (session.user) {
            promiseArray.push(this.storage.set(SESSION_USER_KEY, JSON.stringify(session.user)));
        }
        return forkJoin(promiseArray);
    }

    /**
     * @ngdoc
     * @description
     * Method to clear the tokens stored.
     */
    private _unsetTokens() {
        return forkJoin(
            this.storage.remove(SESSION_STORAGE_KEY),
            this.storage.remove(SESSION_USER_KEY),
            this.storage.remove(SESSION_STORAGE_REFRESH_KEY),
            this.storage.remove(SESSION_SELECTED_NIWA)
        );
    }

    tokenRefreshed(data: any) {
        this.tokenChangedSubject.next(data);
    }
}
