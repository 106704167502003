import { Injectable } from '@angular/core';
import {ApiUrlsService} from '../api-urls/api-urls.service';
import {SessionService} from '../session';
import {CurrentDeviceStatusService} from '../current-device-status/current-device-status.service';
import {Observable, of, pipe} from 'rxjs';
import {ClaimCodeModel} from '../../models/claim-code/claim-code.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';

import * as moment from 'moment';
import {DeviceModel} from '../../models/device/device.model';
import {fromPromise} from 'rxjs/internal-compatibility';
import {ParticleUnitValuesModel} from '../../models/particle-unit-values/particle-unit-values.model';
import {ToastService} from '../toast/toast.service';

@Injectable({
    providedIn: 'root'
})
export class PhotonService {

    sap: any;
    claimResponse: ClaimCodeModel;
    deviceIds = [];

    constructor(
        private http: HttpClient,
        private apiUrlsService: ApiUrlsService,
        private sessionService: SessionService,
        private currentDeviceStatusService: CurrentDeviceStatusService,
        private toastService: ToastService
    ) {
    }

    getClaimCode (): Observable<ClaimCodeModel> {
        const user = this.sessionService.getCurrentUser();
        const userId = user ? user.id : null;
        const token = user && user.particleTokens ? user.particleTokens.access_token : undefined;

        return this.http.post<ClaimCodeModel>(this.apiUrlsService.USER.claimCode(), {
            accessToken: token,
            userId
        }).pipe(map(response => this.claimResponse = ClaimCodeModel.deserialize(response)));
    }

    getDeviceIds (): Observable<any> {
        const user = this.sessionService.getCurrentUser();
        const userId = user ? user.id : null;
        const token = user && user.particleTokens ? user.particleTokens.access_token : undefined;

        const httpOptions = {
            headers: new HttpHeaders({
                accessToken: token,
                userId
            })
        };
        return this.http.get(this.apiUrlsService.PARTILE_UNIT.getDeviceIds(), httpOptions)
            .pipe(tap(deviceIds => this.deviceIds = deviceIds));
    }

    isDeviceAvailable (deviceId: string) {
        return this.deviceIds.indexOf(deviceId) < 0;
    }

    getDevices (): Observable<Array<DeviceModel>> {
        const user = this.sessionService.getCurrentUser();
        const userId = user ? user.id : null;
        const token = user && user.particleTokens ? user.particleTokens.access_token : undefined;

        const httpOptions = {
            headers: new HttpHeaders({
                accessToken: token,
                userId
            })
        };
        return this.http.get(this.apiUrlsService.USER.devices(), httpOptions)
            .pipe(map((response: Array<any>) => response.map((elem) => DeviceModel.deserialize(elem))));
    }

    associateDevice (deviceId: string): Observable<any> {
        const user = this.sessionService.getCurrentUser();

        const timezone = moment().utcOffset() / 60;
        return this.http.post(this.apiUrlsService.USER.associateDevice(), {
            owner: user.id,
            particleId: deviceId,
            timezone,
            name: ''
        });
    }

    updateDevice (deviceId: string, body: string): Observable<any> {
        return this.http.put(this.apiUrlsService.USER.updateDevice(deviceId), body);
    }

    getDeviceInfo (): Observable<any> {
        return fromPromise(new Promise((resolve, reject) => {
            return this.sap.deviceInfo((err, data) => {
                if (err) {
                    return reject(err);
                }
                this.toastService.presentSuccessToast('He llegado', 'top', 3000);
                return resolve(data);
            });
        }));
    }

    getPublicKey (): Observable<any> {
        return fromPromise(new Promise((resolve, reject) => {
            return this.sap.publicKey((err, data) => {
                if (err) {
                    return reject(err);
                }

                return resolve(data);
            });
        }));
    }

    claimDevice (): Observable<any> {
        return fromPromise(new Promise((resolve, reject) => {
            return this.sap.setClaimCode(this.claimResponse.claim_code, (err, data) => {
                if (err) {
                    return reject(err);
                }

                return resolve(data);
            });
        }));
    }

    connectNetwork (ssid, security, password, channel): Observable<any> {
        return fromPromise(new Promise((resolve, reject) => {
            const options = {
                ssid: undefined,
                security: undefined,
                password: undefined,
                channel: undefined
            };

            if (ssid) {
                options.ssid = ssid;
            }

            if (security) {
                options.security = this.sap.securityLookup(security);
            }

            if (password) {
                options.password = password;
            }

            if (channel) {
                options.channel = channel;
            }

            return this.sap.configure(options,
                (err, data) => {
                    if (err) {
                        return reject(err);
                    }
                    return this.sap.connect((err1, data1) => {
                        if (err1) {
                            return reject(err1);
                        }

                        return resolve(data1);
                    });
                })
                ;
        }));
    }

    listNetworks (): Observable<any> {
        return fromPromise(new Promise((resolve, reject) => {
            return this.sap.scan((err, data) => {
                if (err) {
                    return reject(err);
                }

                return resolve(data);
            });
        }));
    }

    getPhotonValues (): Observable<ParticleUnitValuesModel> {
        // -----USE THIS TO TEST. CAN BE REMOVED ONCE THE ENDPOINT IS WORKING----
        // const a = new ParticleUnitValuesModel();
        // a.fan = Math.round(Math.random());
        // a.heater = Math.round(Math.random());
        // a.humidity = Math.floor(Math.random() * 3) + 1
        // a.lamp = Math.floor(Math.random() * 100)
        // a.light = Math.round(Math.random());
        // a.temperature = Math.floor(Math.random() * 40);
        // a.water = 0;
        // a.watering = Math.round(Math.random());

        // return Observable.create((ob) => {
        //     ob.next(a);
        //     ob.complete();
        // });
        // -----USE THIS TO TEST. CAN BE REMOVED ONCE THE ENDPOINT IS WORKING----
        return this.http.get(this.apiUrlsService.PARTILE_UNIT.getData(this.currentDeviceStatusService.getParticleUnit().particleId))
            .pipe(
                map((response) => ParticleUnitValuesModel.deserialize(response)),
                catchError((error) => {
                    return of(null);
                })
            );
    }

    getDeviceValues(deviceId: string) {
        return this.http.get(this.apiUrlsService.PARTILE_UNIT.getDeviceData(deviceId))
            .pipe(
                map((response) => ParticleUnitValuesModel.deserialize(response)),
                catchError((error) => {
                    return of(null);
                })
            );
    }
}
