import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';
import {IconService} from '../../services/icon/icon.service';

@Component({
  selector: 'nw-input-field',
  templateUrl: './input-field.component.html',
    styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent implements OnInit {
    @Input() icon: string;
    @Input() iconColor: string;
    @Input() label: string;
    @Input() name: string;
    @Input() value: string;
    @Input() type: string;
    @Input() modifiers: string;
    @Input() group: FormGroup;
    @Input() groupName: string;
    @Input() isReadOnly = false;
    @Input() isRequired = true;
    @Input() placeholder: string;

    constructor(
        private iconService: IconService
    ) {}

    ngOnInit() {}

    getIconPath (icon: string) {
        return this.iconService.load(icon);
    }

    generateMessage(message: any) {
        if (message._errors && message._errors.required) {
            return 'Field is required';
        } else if (message._errors && message._errors.pattern) {
            return 'This is not a valid phone';
        } else if (message._errors
            && message._errors.minlength
            && message._errors.minlength.actualLength < message._errors.minlength.requiredLength) {
                return 'This field must have ' + message._errors.minlength.requiredLength + ' characters';
        } else if (message._errors
            && message._errors.maxlength
            && message._errors.maxlength.actualLength > message._errors.maxlength.requiredLength ) {
                return 'This field must have ' + message._errors.maxlength.requiredLength + ' characters';
        } else if (message._errors && message._errors.notEqual) {
            return 'Passwords doesn\'t match';
        }

        return 'This field has an error';
    }

    clear() {
        this.group.controls[this.groupName].markAsUntouched();
    }
}

/**
 * @ngdoc
 * @name      fieldEqualValidator
 * @description
 * Method that returns a `ValidatorFn` to validate if two fields are equal.
 * @param       {string}            firstFieldKey  The control key for the first field
 * @param       {string}            secondFieldKey The control key for the second field
 * @return      {ValidatorFn}                      The function that will be called to validate
 */
export function fieldEqualValidator (firstFieldKey: string, secondFieldKey: string): ValidatorFn {
    return (group: FormGroup): {[key: string] : any;} => {
        const firstField = group.controls[firstFieldKey];
        const secondField = group.controls[secondFieldKey];

        if (firstField.value !== secondField.value) {
            // Set the error on the second field as well
            secondField.setErrors({
                notEqual: true
            });

            return {
                notEqual: true
            }
        }

        // Clear the error on the second field
        secondField.setErrors(secondField.validator(secondField));
    }
}
