import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  private resumeSubject = new Subject();
  private pauseSubject = new Subject();

  constructor (
      private platform: Platform
  ) { }

  init () {
    this.platform.resume
        .subscribe(() => this.resumeSubject.next())
    ;

    this.platform.pause
        .subscribe(() => this.pauseSubject.next())
    ;
  }

  getResumeSubject () {
    return this.resumeSubject.asObservable();
  }

  getPauseSubject () {
    return this.pauseSubject.asObservable();
  }
}
