export const environment = {
    // BASE_API_URL: 'http://localhost:3000',
    BASE_API_URL: 'https://v2.api.niwa.io',
    GOOGLE_CLIENT_ID: '826861652825-d3420g5oivv4n5s2ak8c4s8c2rg1a9do.apps.googleusercontent.com',
    CLOUDINARY: {
        CLOUD_NAME: 'dsv9kl8ij',
        UPLOAD_PRESET: 'we2i6lm1'
    },
    SUPPORT_EMAIL: 'support@getniwa.com'
}
