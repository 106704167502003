import {AlarmsSettingsModel} from '../alarms-settings/alarms-settings.model';
import {GrowRecipeInstanceModel} from '../grow-recipe-instance/grow-recipe-instance.model';

export class IoTDeviceModel {
    id: string;
    bssId: string;
    awsId: string;
    relays: string[];
    growRecipeInstance: GrowRecipeInstanceModel;
    name: string;
    alarmsSettings: AlarmsSettingsModel;
    timezone: string;
    tmpOff: number;
    rhOff: number;
    leafTempOff: number;

    static deserialize(jsonObject: any): IoTDeviceModel {
        if (!jsonObject) {
            return undefined;
        }

        const growRecipeInstanceHydrator = (program) => GrowRecipeInstanceModel.deserialize(program);

        const instance = new IoTDeviceModel();

        const mapProperties = {
            growRecipeInstance: growRecipeInstanceHydrator,
        };

        Object.keys(jsonObject).forEach(prop => {
            if (prop === '_growProgramInstance') {
                instance.growRecipeInstance = mapProperties.growRecipeInstance(jsonObject._growProgramInstance);
            } else if (prop === 'alarmsSettings') {
                instance.alarmsSettings = AlarmsSettingsModel.deserialize(jsonObject[prop]);
            } else if (prop === '_id') {
                instance.id = jsonObject._id;
            } else {
                instance[prop] = mapProperties[prop] !== undefined
                    ? mapProperties[prop](jsonObject[prop])
                    : jsonObject[prop];
            }
        });

        return instance;
    }
}
