import { Injectable } from '@angular/core';
import {Observable, Subject, merge} from 'rxjs';
import {flatMap} from 'rxjs/operators';
import {PhotonService} from '../photon/photon.service';

@Injectable({
    providedIn: 'root'
})
export class ParticleUnitValuesReloaderService {
    private updateSubject = new Subject();

    constructor (private photonService: PhotonService) {}

    getCurrentValuesSubject () {
        return merge(
            this.photonService.getPhotonValues(),
            this.updateSubject.pipe(flatMap(() => this.photonService.getPhotonValues())),

        );
    }

    getDeviceValues(deviceId: string) {
        return merge(
            this.photonService.getDeviceValues(deviceId),
            this.updateSubject.pipe(flatMap(() => this.photonService.getDeviceValues(deviceId))),

        );
    }

    updateCurrentValues () {
        this.updateSubject.next();
    }
}
