
import { environment } from '../../../environments/environments.dev';
import { StageModel } from './stage.model';

/**
 * Data model for grow program
 */
export class GrowRecipeModel {
    public id: string;
    public name: string;
    public status: string;
    public smallDescription: string;
    public description: string;
    public creator: string;
    public stages: Array<StageModel>;
    public publicImgURL: string;
    public growProgramInstances: any[];

    constructor() {
        this.name = '';
        // this.status = '';
        this.smallDescription = '';
        // this.description = '';
        this.stages = [];
        this.publicImgURL = 'niwa-app/tomato';
        this.creator = '';
    }

    static deserialize(jsonObject: any): GrowRecipeModel {
        const instance = new GrowRecipeModel();

        const stagesHydrator = (jsonPayload: Array<any>) => jsonPayload.map(element => StageModel.deserialize(element));

        if (jsonObject === undefined || jsonObject === null) {
            return undefined;
        }

        const mapProperties = {
            stages: stagesHydrator
        };

        Object.keys(jsonObject).forEach(prop => {
            if (prop === '_id') {
                instance.id = jsonObject._id;
            } else {
                instance[prop] = mapProperties[prop] !== undefined
                    ? mapProperties[prop](jsonObject[prop])
                    : jsonObject[prop];
            }
        });

        return instance;
    }

    getImage() {
        return 'https://res.cloudinary.com/' + environment.CLOUDINARY.CLOUD_NAME + '/image/upload/' + this.publicImgURL;
    }

}
