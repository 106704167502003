import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  icons: string[];

  constructor(
  ) {
    this.icons = [];
  }

  register(key, value) {
    this.icons[key] = value;
  }

  load(key): string {
    return this.icons[key];
  }
}
