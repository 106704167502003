import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: 'sign-in',
        loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInPageModule)
    },
    {
        path: 'sign-up',
        loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpPageModule)
    },
    {
        path: 'dashboard',
        pathMatch: 'full',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
    },
    {
        path: 'my-account',
        loadChildren: () => import('./pages/my-account/my-account.module').then( m => m.MyAccountPageModule)
    },
    {
        path: 'grow-recipes',
        loadChildren: () => import('./pages/grow-recipes/grow-recipes.module').then(m => m.GrowRecipesModule)
    },
    {
        path: 'data-logs',
        loadChildren: () => import('./pages/data-logs/data-logs.module').then( m => m.DataLogsPageModule)
    },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
    },
    {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
    },
    {
        path: 'recipe-setting',
        loadChildren: () => import('./pages/recipe-setting/recipe-setting.module').then( m => m.RecipeSettingPageModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
