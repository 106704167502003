export class DeviceModel {
    id: string;
    name: string;
    last_app: null;
    last_ip_address: string;
    last_heard: Date;
    product_id: number;
    connected: boolean;
    platform_id: number;
    cellular: boolean;
    status: string;
    current_build_target: string;
    default_build_target: string;

    static deserialize (jsonObject: any): DeviceModel {
        if (!jsonObject) {
            return undefined;
        }

        const instance = new DeviceModel();

        const dateHydrator = (date: string) => date !== undefined ? new Date(date) : undefined;

        const mapProperties = {
            last_heard: dateHydrator
        };

        Object.keys(jsonObject).forEach(prop =>
            instance[prop] = mapProperties[prop] !== undefined
                ? mapProperties[prop](jsonObject[prop])
                : jsonObject[prop]
        );

        return instance;
    }
}

