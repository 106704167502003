export class AlarmsSettingsModel {
    t: {
        min: number,
        max: number,
        enabled: boolean
    };
    rh: {
        min: number,
        max: number,
        enabled: boolean
    };
    tr: {
        maxDeviation: number,
        enabled: boolean
    };
    rhr: {
        maxDeviation: number,
        enabled: boolean
    };
    l: {
        enabled: boolean
    };

    static deserialize(jsonObject: any): AlarmsSettingsModel {
        const instance = new AlarmsSettingsModel();

        if (jsonObject === undefined || jsonObject === null) {
            return undefined;
        }

        Object.keys(jsonObject).forEach(prop => {
                instance[prop] = jsonObject[prop];
        });
        return instance;
    }
}
