import { Directive, ElementRef } from '@angular/core';
// import { Keyboard } from '@ionic-native/keyboard';
import { OnInit, OnDestroy } from '@angular/core';
@Directive({ selector: '[backgroundChanger]' })
export class BackgroundChangerDirective implements OnInit, OnDestroy {
    element: ElementRef;
    onSubscription: any;
    offSubscription: any;

    constructor(
        el: ElementRef,
        // private keyboard: Keyboard
    ) {
        this.element = el;
    }

    ngOnInit() {
        /* this.onSubscription = this.keyboard.onKeyboardShow().subscribe(() => {
            this.element.nativeElement.style.minHeight = '100%';
        });

        this.offSubscription = this.keyboard.onKeyboardHide().subscribe(() => {
            this.element.nativeElement.style.minHeight = 'auto';
        }); */
    }

    ngOnDestroy() {
        if (this.onSubscription) {
            this.onSubscription.unsubscribe();
        }

        if (this.offSubscription) {
            this.offSubscription.unsubscribe();
        }
    }
}
