export class ClaimCodeModel {
    claim_code: string;
    device_ids: string[];

    static deserialize (jsonObject: any): ClaimCodeModel {
        if (!jsonObject) {
            return undefined;
        }

        const instance = new ClaimCodeModel();

        const mapProperties = {
        };

        Object.keys(jsonObject).forEach(prop =>
            instance[prop] = mapProperties[prop] !== undefined
                ? mapProperties[prop](jsonObject[prop])
                : jsonObject[prop]
        );

        return instance;
    }
}
