import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';

import { SpinnerComponent} from './components';

import {IconService} from './services/icon/icon.service';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {StartupService} from './services/startup/startup.service';
import {ParticleUnitValuesReloaderService} from './services/particle-unit-values-reloader/particle-unit-values-reloader.service';
import {AuthInterceptorService} from './services/auth-interceptor/auth-interceptor.service';
import {Network} from '@ionic-native/network/ngx';
import {BackgroundChangerDirective} from './directives/background-changer/background-changer.directive';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import {EmailComposer} from '@ionic-native/email-composer/ngx';
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { WifiWizard2 } from '@ionic-native/wifi-wizard-2/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';

export function provideStorage() {
    return new Storage({
        driverOrder: ['sqlite', 'websql', 'indexeddb'],
        name: 'niwa_db'
    });
}

@NgModule({
    declarations: [
        AppComponent,
        SpinnerComponent,

        // directives
        BackgroundChangerDirective,
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: Storage, useFactory: provideStorage },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
        LocalNotifications,
        ParticleUnitValuesReloaderService,
        Network,
        InAppBrowser,
        EmailComposer,
        FCM,
        WifiWizard2,
        Geolocation,
        AppVersion,
        OpenNativeSettings
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor (
        iconService: IconService,
        startupService: StartupService
    ) {
        iconService.register('alert', './assets/icon/alert.svg');
        iconService.register('delete', './assets/icon/delete.svg');
        iconService.register('back', './assets/icon/back.svg');
        iconService.register('connect', './assets/icon/connect.svg');
        iconService.register('facebook', './assets/icon/facebook.svg');
        iconService.register('email', './assets/icon/email.svg');
        iconService.register('email-fill', './assets/icon/email-fill.svg');
        iconService.register('google', './assets/icon/google.svg');
        iconService.register('logo', './assets/icon/logo.svg');
        iconService.register('logo-mini', './assets/icon/logo-mini.svg');
        iconService.register('locked', './assets/icon/locked.svg');
        iconService.register('unlocked', './assets/icon/unlocked.svg');
        iconService.register('play', './assets/icon/play.svg');
        iconService.register('send', './assets/icon/send.svg');
        iconService.register('menu', './assets/icon/menu.svg');
        iconService.register('toDo', './assets/icon/to-do.svg');
        iconService.register('fan', './assets/icon/fan.svg');
        iconService.register('heater', './assets/icon/heater.svg');
        iconService.register('watering', './assets/icon/watering.svg');
        iconService.register('light_footer', './assets/icon/light_footer.svg');
        iconService.register('yes', './assets/icon/yes-circle.svg');
        iconService.register('wifi', './assets/icon/wi-fi.svg');
        iconService.register('plug', './assets/icon/plugged-in.svg');
        iconService.register('logo-one', './assets/icon/logo-niwa-one.svg');
        iconService.register('temperature', './assets/icon/temperature.svg');
        iconService.register('check', './assets/icon/check.svg');
        iconService.register('pulse', './assets/icon/pulse.svg');
        iconService.register('pencil', './assets/icon/pencil.svg');

        // Initializes currentDeviceStatusService
        startupService.run();
    }
}
