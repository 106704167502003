/**
 * Data model for creator model
 */
export class StageModel {
    // name: string;
    // description: string;
    // expectedDuration: string;
    icon: string;
    scheduledWateringDays: string[];
    dailyActions: {
        // pump: Array<{ startHour: number, endHour: number, waterFor: number, waterEvery: number }>,
        pump: Array<{ sH: any, eH: any }>,
        lamp: Array<{ sH: any, eH: any }>,
        climate: Array<{ sH: any, eH: any, tempTarget: number, rhTarget: number, origonaltempTarget?:number,stageTemp?:number}>
        ventilation: Array<{ sH: number, eH: number, target: string }>,
    };
    // question: Array<string>;
    // toDo: Array<string>;

    constructor() {
        // this.name = '';
        // this.description = '';
        // this.expectedDuration = '';
        this.icon = '';
        this.scheduledWateringDays = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];
        this.dailyActions = {
            pump: [],
            lamp: [],
            climate: [],
            ventilation: [],
        };
        // this.question = [];
        // this.toDo = [];
    }

    static isProperty(propertyString) {
        
        const properties = ['name', 'description', 'expectedDuration', 'icon', 'dailyActions', 'question', 'toDo'];
       
        return properties.indexOf(propertyString) > -1;
    }

    static deserialize(jsonObject: any): StageModel {
        const instance = new StageModel();

        if (jsonObject === undefined || jsonObject === null) {
            return undefined;
        }

        Object.keys(jsonObject).forEach(prop => instance[prop] = jsonObject[prop]);

        return instance;
    }
}
