import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'nw-spinner',
    templateUrl: './spinner.component.html'
})
export class SpinnerComponent implements OnInit {
    @Input() width = 50;
    @Input() height = 50;

    public fromValue;
    public toValue;

    constructor() {
    }

    ngOnInit() {
        this.fromValue = `0 ${this.width / 2}px ${this.height / 2}px`;
        this.toValue = `360 ${this.width / 2}pxx ${this.height / 2}px`;
    }
}
