import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavController, Platform} from '@ionic/angular';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {SpinnerService} from './services/spinner/spinner.service';
import {SessionService} from './services/session';
import { Subject } from 'rxjs';
import { PlatformService } from './services/platform/platform.service';
import { IoTDeviceModel } from './models/iot-device/iot-device.model';
import { IoTDeviceService } from './services/iot-device/iot-device.service';
import { CurrentDeviceStatusService } from './services/current-device-status/current-device-status.service';
import { ActivatedRoute } from '@angular/router';
import { SessionModel } from './models/session/session.model';
import { Storage } from '@ionic/storage';
import { UserService } from './services/user/user.service';
import { UserModel } from './models/user';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    showSpinner = false;
    blocking = false;

    private ngUnsubscribe = new Subject<void>();
    private subscription: any;
    private currentUser: UserModel;

    constructor(
        private platform: Platform,
        private statusBar: StatusBar,
        private spinnerService: SpinnerService,
        private sessionService: SessionService,
        private navCtrl: NavController,
        private platformService: PlatformService,
        private iotDeviceService: IoTDeviceService,
        private currentDeviceStatusService: CurrentDeviceStatusService,
        private activatedRoute: ActivatedRoute,
        private storage: Storage,
        private userService: UserService
    ) {
        this.initializeApp();

        this.platform.pause.subscribe(() => {
            if (this.currentDeviceStatusService.getIoTDevice()) {
                this.sendLiveDashboardMessage(this.currentDeviceStatusService.getIoTDevice(), '3');
            }
        });
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe( queryParam => {
            
            if(queryParam.user) {
                this.sessionService.logout();
                this.storage.clear();
                if(JSON.parse(queryParam.user)) {
                    this.currentUser = JSON.parse(queryParam.user);

                    const session: SessionModel = new SessionModel(this.currentUser.authToken.accessToken, this.currentUser.authToken.refreshToken, this.currentUser.authToken.tokenId, this.currentUser);
                    this.sessionService.setSessionAsync(session).subscribe(() => {
                        
                        this.userService.getUser(this.currentUser.id).subscribe((user) => {
                            this.currentUser = user;
                            const sessionNew: SessionModel = new SessionModel(user.authToken.accessToken, user.authToken.refreshToken, user.authToken.tokenId, user);
                            this.setSession(sessionNew);
                        });
                    });
                }
            }
        });

        this.sessionService.tokenAnnounced$.subscribe((resp) => {
            this.userService.getUser(this.currentUser.id).subscribe((user) => {
                this.currentUser = user;
                resp.user = user;
                this.setSession(resp);
            });
        });

        this.platformService.init();

        this.spinnerService.getSpinnerState().subscribe((show) => {
            this.showSpinner = show;
        });

        this.spinnerService.getSpinnerBlockingState().subscribe((blocking) => {
            this.blocking = blocking;
        });
    }

    ionViewDidEnter() {
        console.log('viewEnter');
        this.subscription = this.platform.backButton.subscribeWithPriority(9999, null);
    }

    ionViewWillLeave() {
        console.log('vieleaver');
        this.subscription.unsubscribe();
    }

    ngOnDestroy () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleBlackOpaque();
        });
    }

    sendLiveDashboardMessage(iotDevice: IoTDeviceModel, payload: string) {
        const currentDevice = iotDevice;
        // tslint:disable-next-line:max-line-length
        const topic = `$aws/things/niwa_test_group/niwaesp32_${currentDevice.bssId.toUpperCase() + '/' + currentDevice.bssId.toUpperCase()}/device/control/config`;
        this.iotDeviceService.publishCertLiveDashMqttMessage(topic, payload).subscribe(response => {
            return response;
        });
    }

    private setSession(session: SessionModel) {
        this.sessionService.setSessionAsync(session).subscribe(() => {
            this.currentDeviceStatusService.setIoTDevice(this.currentUser.devices[0]);
            this.navCtrl.navigateRoot('/home');
        });
    }

    

    
}
