import { GrowRecipeModel } from '../grow-recipe/grow-recipe.model';
import { StageInstanceModel } from './stages-instance.model';

/**
 * Data model for grow program instance
 */
export class GrowRecipeInstanceModel {
    public id: string;
    public growRecipe: GrowRecipeModel;
    public stages: Array<StageInstanceModel>;

    constructor() {
        this.growRecipe = new GrowRecipeModel();
    }

    static deserialize(jsonObject: any): GrowRecipeInstanceModel {
        const instance = new GrowRecipeInstanceModel();

        const growProgramHydrator = (jsonPayload: Object) => GrowRecipeModel.deserialize(jsonPayload);
        const stagesHydrator = (jsonPayload: Array<Object>) => jsonPayload.map(element => StageInstanceModel.deserialize(element));

        if (jsonObject === undefined || jsonObject === null) {
            return undefined;
        }

        const mapProperties = {
            growRecipe: growProgramHydrator,
            stages: stagesHydrator
        };

        Object.keys(jsonObject).forEach(prop => {
            if (prop === '_id') {
                instance['id'] = jsonObject['_id'];
            } else {
                instance[prop] = mapProperties[prop] !== undefined
                    ? mapProperties[prop](jsonObject[prop])
                    : jsonObject[prop];
            }
        });

        return instance;
    }

    getImage () {
        return this.growRecipe ? this.growRecipe.getImage(): null;
    }
}
