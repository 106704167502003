import { Injectable } from '@angular/core';
import {ToastController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(public toastController: ToastController) {}

    async presentToast(message: string, position, duration, cssClass) {
        const toast = await this.toastController.create({
            message,
            position,
            duration,
            cssClass
        });
        await toast.present();
    }

    async presentSuccessToast(message: string, position: string, duration: number) {
        this.presentToast(message, position, duration, 'toast-success');
    }

    async presentErrorToast(message: string, position: string, duration: number) {
        this.presentToast(message, position, duration, 'toast-error');
    }

    async showGenericErrorMessage () {
        this.presentToast('It wasn\'t possible to complete this request. Please try again later.', 'top', 3000, 'toast-error');
    }
}
