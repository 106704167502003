export class ParticleUnitValuesModel {
    light: number;
    water: number;
    humidity: number;
    temperature: number;
    heater: number;
    watering: number;
    lamp: number;
    fan: number;
    particleId: string;
    deviceId: string;

    static deserialize(jsonObject: any): ParticleUnitValuesModel {
        const instance = new ParticleUnitValuesModel();

        if (jsonObject === undefined || jsonObject === null) {
            return undefined;
        }

        Object.keys(jsonObject).forEach(prop => instance[prop] = jsonObject[prop]);

        return instance;
    }
}
