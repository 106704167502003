import { Roles, RolesNs } from './roles.model';
import { ParticleModel } from './particle.model';
import { ParticleUnitModel } from '../particle-unit/particle-unit.model';
import { AlarmsSettingsModel } from '../alarms-settings/alarms-settings.model';
import {IoTDeviceModel} from '../iot-device/iot-device.model';
import { SessionModel } from '../session/session.model';

export const ROLE_GROWER = {
    key: Roles.GROWER,
    value: 'Grower'
};

export const ROLES = [ROLE_GROWER];

export class UserModel {
    id: string;
    username: string;
    providerEmail: string;
    cognitoId: string;
    roles: Array<Roles> = [];
    provider: string;
    providerId: string;
    particleTokens: ParticleModel;
    particles: Array<ParticleUnitModel>;
    devices: Array<IoTDeviceModel>;
    temperatureScale: string;
    alarmsSettings: AlarmsSettingsModel;
    fcmTokens: string[];
    authToken?: SessionModel;

    static deserialize (jsonObject: any): UserModel {
        if (!jsonObject) {
            return undefined;
        }

        const instance = new UserModel();

        const rolesHydrator = (arrayRoles: Array<any>) => arrayRoles.map(role => RolesNs.fromString(role));
        // const particleUnitHydrator = (units: Array<any>) => units.map(element => ParticleUnitModel.deserialize(element));
        const iotDeviceHydrator = (devices: Array<any>) => devices.map(element => IoTDeviceModel.deserialize(element));

        const mapProperties = {
            roles: rolesHydrator,
            // particles: particleUnitHydrator,
            devices: iotDeviceHydrator
        };

        Object.keys(jsonObject).forEach(prop => {
            if (prop === '_particles') {
                // instance.particles = mapProperties.particles(jsonObject._particles);
            } else {
                instance[prop] = mapProperties[prop] !== undefined
                    ? mapProperties[prop](jsonObject[prop])
                    : jsonObject[prop];
            }
        });
        return instance;
    }

    /**
     * Returns the particle unit for the specified index
     * @param index index of the array of user particles
     */
    getParticleUnit (index: number) {
        // We can assume 0 is the one. For now there will be will be only one.
        // return this.particles.length ? this.particles[index] : undefined;
    }


    public setFcmTokens ( newToken : string ): boolean {

        let pass: boolean = false;

        if (!this.fcmTokens.includes(newToken)) {

            this.fcmTokens.push(newToken);

            pass = true;

        }


        return pass;

    }
}
