export enum Roles {
    GROWER= 'GROWER'
}

export namespace RolesNs {
    export function toString(routingKey: Roles) {
        if (routingKey === undefined || routingKey === null) {
            return undefined;
        }

        switch (routingKey) {
            case Roles.GROWER:
                return 'GROWER';
            default:
                throw new Error('Invalid routing key specified');
        }
    }

    export function fromString(aRoutingKey: string) {
        if (aRoutingKey === undefined || aRoutingKey === null) {
            return undefined;
        }

        switch (aRoutingKey) {
            case 'GROWER':
                return Roles.GROWER;
            default:
                return undefined;
        }
    }
}
